<template lang="pug">
v-app
  v-content
    router-view
</template>

<style lang="scss">
@import "./plugins/meltline.css";
@import "@/scss/_variables.scss";

html, body {
  margin: 0;
  // height: 100vh;
  font-family: $gennokaku_gothic;
  scroll-behavior: smooth;
}

.v-application--wrap {
  min-height: -webkit-fill-available;
}

span, p, a, textarea, textarea::placeholder {
  // font-size: 14px;
  font-feature-settings: 'palt';
  letter-spacing: 0.04em;
  font-family: "Helvetica Neue" , Helvetica , Arial , Verdana , Roboto , "游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "メイリオ" , Meiryo , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
}

a {
  color: $active_color !important;
}

// スクロールバーを全て削除
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display:none;
  }
}
</style>

<script>

export default {
  name: 'App'
};
</script>
